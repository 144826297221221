import * as React from 'react'
import * as b_ from 'b_'

import './index.scss';

const b = b_.with('about');

const About = () => 
  <section className={b()}>
    <div className={b("me")}></div>
    <div className={b("text")}>
      <p>Меня зовут Валентина Теркова. Или просто Тёрка. Живу и работаю я в Комсомольске-на-Амуре. Этот городок находится на востоке России; и его не сразу найдешь на карте. Работаю я, кстати, радиоведущей. Каждый день встаю в 5-6 утра и поднимаю людям настроение. Или они мне. В общем, это то, что заставляет меня улыбаться даже рано утром.</p>
      <p>Ещё я валяю разные штуки. Шерсть - это тот материал, который позволяет воплотить любую задумку. Который терпит ошибки и дарит тепло.</p>
      <p>Керамика - моя вторая любовь. Любовь и учитель. Это то, что учит меня буддийскому спокойствию. Потому что иногда случается, что все твои многодневные труды погибают в печи. В этот момент остаётся лишь улыбнуться, принять этот опыт. И продолжить.</p>
      <p>Так и живу. Улыбаюсь, дарю тепло и продолжаю работать, несмотря на ошибки.</p>
    </div>
  </section>

export default About;